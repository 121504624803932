import React from "react";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ConfirmImageUploader from "./modals/confirmImageUploader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate, useParams } from "react-router-dom";
import noPreview from "../assets/images/homestay.gif";

const AddCoverPhoto = ({
  next,
  prev,
  formik,
  coverContainer,
  setCoverContainer,
  handleSubmitCoverImage,
}) => {

  const { propertyId } = useParams();
  const navigate = useNavigate();
  const handleClose = () => {
    setCoverContainer({
      ...coverContainer,
      // coverImage: formik?.values?.cover_image || "",
      coverModel: false,
    });
  };
  return (
    <Box
      // sx={{ mt: 2 }}
      component={"form"}
      // onSubmit={(e) => submitImageUpload(e)}
      className="form_container"
    >
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          <Typography variant="h4" sx={{ fontWeight: "700" }}>
            Cover Photo
          </Typography>

          <Typography>
            Your cover photos is a guest's first impression of your listing
          </Typography>
          <Button
            variant="outlined"
            sx={{ mt: 3 }}
            onClick={() =>
              setCoverContainer({ ...coverContainer, coverModel: true })
            }
          >
            Change Photo
          </Button>
          <Box display="flex" mt="20px">
            <Button
              className="prev"
              variant="outlined"
              sx={{ mr: 2 }}
              onClick={() => prev()}
            >
              <ArrowBackIcon />
            </Button>
            {propertyId && (
              <Button
                variant="outlined"
                className="next"
                sx={{ mr: 3 }}
                onClick={() => navigate('/properties')}
              >
                <ArrowForwardIcon />
              </Button>
            )}
          </Box>
        </Box>
        {coverContainer?.coverImage ? (
          <Box
            component={"img"}
            src={coverContainer?.coverImage}
            alt="product_img"
            className="cover_photo_img"
            onError={(event) => {
              event.target.src = noPreview;
              // event.currentTarget.className = "error_img";
              event.onerror = null;
            }}
          />
        ) : (
          <Box
            component={"img"}
            src={require("../assets/images/homestay.gif")}
            alt="product_img"
            className="cover_photo_img"
          />
        )}
      </Box>

      {/* )} */}
      {coverContainer?.coverModel && (
        <ConfirmImageUploader
          handleClose={handleClose}
          formik={formik}
          coverContainer={coverContainer}
          setCoverContainer={setCoverContainer}
          handleSubmitCoverImage={handleSubmitCoverImage}
          propertyId={propertyId}
        />
      )}
    </Box>
  );
};

export default AddCoverPhoto;
