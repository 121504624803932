import React from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { imageTypes } from "../utils/mockData";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./index.css";

const AddThumbnails = ({
  next,
  prev,
  loading,
  thumbnails,
  activeStep,
  handleImageUpload,
  submitImageUpload,
  removeImageFromContainer,
}) => {
  const { propertyId } = useParams();
  return (
    <Box
      component={"form"}
      onSubmit={(e) => submitImageUpload(e)}
      className="form_container"
    >
      <div>
        <h1>Rooms and Spaces</h1>
        <p>Add areas guests can use and mark any spaces they will share.</p>
      </div>

      {thumbnails?.map((list, index) => (
        <Accordion sx={{ mt: 4 }} className="accordion_main" key={index}>
          <AccordionSummary
            style={{ padding: "2%" }}
            expandIcon={<ExpandMoreIcon sx={{ width: "2em", height: "2em" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={{
                fontSize: "1.5rem",
                fontWeight: "600",
                textTransform: "capitalize",
              }}
            >
              {`${
                imageTypes?.find((img) => img?.type === list?.type)?.label ||
                list?.label
              } (${list?.images?.length || 0})`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              className={
                list?.images?.length > 0
                  ? "images_box_with_data"
                  : "images_box_with_nodata"
              }
              key={index}
            >
              {list?.images.length > 0 ? (
                list?.images?.map((imageUrl, i) => (
                  <Box
                    sx={{
                      position: "relative",
                      width: "340px",
                      objectFit: "fill",
                      margin: "0px 3px",
                      height: "266px",
                    }}
                    key={i}
                  >
                    <Box
                      className="cross__container"
                      onClick={() =>
                        removeImageFromContainer(i, index, imageUrl)
                      }
                    >
                      <ClearIcon />
                    </Box>
                    <Box
                      component={"img"}
                      src={
                        propertyId && typeof imageUrl === "string"
                          ? `${imageUrl}`
                          : URL.createObjectURL(imageUrl)
                      }
                      alt={`thumbnail_${list?.type}`}
                      className="preview-image"
                      width="100%"
                      height="100%"
                    />
                  </Box>
                ))
              ) : (
                <p>No Images.</p>
              )}
            </Box>
            <Box className="add_img_div">
              <Button component="label" variant="outlined">
                + Add Images
                <input
                  type="file"
                  name="images"
                  accept="image/*"
                  hidden
                  multiple
                  onChange={(e) => handleImageUpload(e, list?.type, index)}
                />
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
      <Box display="flex" justifyContent="end" mt="20px">
        <Button
          className="prev"
          variant="outlined"
          sx={{ mr: 2 }}
          onClick={() => prev()}
        >
          <ArrowBackIcon />
        </Button>
        {(activeStep?.id || propertyId) && (
          <Button
            variant="outlined"
            className="next"
            sx={{ mr: 3 }}
            onClick={() => next()}
          >
            <ArrowForwardIcon />
          </Button>
        )}
        {loading?.thumbnailLoading ? (
          <Button color="secondary" variant="outlined">
            <CircularProgress size={20} />
          </Button>
        ) : (
          <Button type="submit" variant="outlined">
            Add thumbnail
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AddThumbnails;
