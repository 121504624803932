import { Box } from "@mui/system";
import React from "react";
import { mockAmenities, houseRules, safetyDevice } from "../utils/mockData";
import { Button, CircularProgress, Divider, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./index.css";

const AddRulesAndSafety = ({
  next,
  prev,
  loading,
  nestedArray,
  handleAmenities,
  submitPropertyRules,
}) => {
  const { propertyId } = useParams();
  return (
    <Box>
      <Box>
        <Typography className="form___title__text rules_text">Add House Rules</Typography>
        <Box sx={{ width: "100%", mt: 6 }}>
          {houseRules?.map((rule) => (
            <Box key={rule}>
              <Box
                sx={{
                  margin: "20px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "17px" }}> {rule}</Typography>
                <Box>
                  <ClearIcon
                    className={`icons_class`}
                    onClick={() =>
                      handleAmenities(rule, "remove", "house_roles")
                    }
                  />
                  <DoneIcon
                    className={`icons_class ${
                      nestedArray?.house_roles?.find(
                        (amenity) => amenity === rule
                      )
                        ? "active_amenity"
                        : ""
                    }`}
                    onClick={() => handleAmenities(rule, "add", "house_roles")}
                  />
                </Box>
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>

        <Typography className="form___title__text rules_text">
          Add Safety Device
        </Typography>
        <Box sx={{ width: "100%", mt: 6 }}>
          {safetyDevice?.map((device) => (
            <Box key={device}>
              <Box
                sx={{
                  margin: "20px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "17px" }}> {device}</Typography>
                <Box>
                  <ClearIcon
                    className={`icons_class`}
                    onClick={() =>
                      handleAmenities(device, "remove", "safety_property")
                    }
                  />
                  <DoneIcon
                    className={`icons_class ${
                      nestedArray?.safety_property?.find(
                        (amenity) => amenity === device
                      )
                        ? "active_amenity"
                        : ""
                    }`}
                    onClick={() =>
                      handleAmenities(device, "add", "safety_property")
                    }
                  />
                </Box>
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
      </Box>
      <Typography className="form___title__text rules_text">Add Amenities</Typography>
      <Box sx={{ width: "100%", mt: 6 }}>
        {mockAmenities?.map((amenities) => (
          <Box key={amenities}>
            <Box
              sx={{
                margin: "20px 0px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: "17px" }}> {amenities}</Typography>
              <Box>
                <ClearIcon
                  className={`icons_class`}
                  onClick={() => handleAmenities(amenities, "remove","amenities")}
                />
                <DoneIcon
                  className={`icons_class ${
                    nestedArray?.amenities?.find(
                      (amenity) => amenity === amenities
                    )
                      ? "active_amenity"
                      : ""
                  }`}
                  onClick={() => handleAmenities(amenities, "add","amenities")}
                />
              </Box>
            </Box>
            <Divider />
          </Box>
        ))}
      </Box>
      <Box display="flex" justifyContent="end" mt="20px">
        <Button
          className="prev"
          variant="outlined"
          sx={{ mr: 2 }}
          onClick={() => prev()}
        >
          <ArrowBackIcon />
        </Button>
        <Button
          variant="outlined"
          className="next"
          sx={{ mr: 3 }}
          onClick={() => next()}
        >
          <ArrowForwardIcon />
        </Button>
        {loading?.propertyRule ? (
          <Button color="secondary" variant="outlined">
            <CircularProgress size={20} />
          </Button>
        ) : (
          <Button
            type="submit"
            variant="outlined"
            onClick={() => submitPropertyRules()}
          >
            {propertyId ? "Update Rules" : "Add Rules"}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AddRulesAndSafety;
