import React, { useEffect, useState } from "react";
import { Box, Button, Tooltip, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import API from "../../config/api";
import DownloadIcon from "@mui/icons-material/Download";
import { toast } from "react-toastify";
import { exportBookingList } from "../../utils/helper";
import moment from "moment";

const BookingList = ({ user }) => {
  const api = new API();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [bookings, setBookings] = useState({
    loading: true,
    data: [],
  });

  useEffect(() => {
    fetchBookings();
  }, []);

  const fetchBookings = async () => {
    const response = await api.get(`form/booking`);
    if (response?.success) {
      setBookings({ loading: false, data: response?.data });
    }
  };
  const handleExportDetails = () => {
    toast.success("Booking list exporting ...");
    exportBookingList(bookings?.data);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: ({ row: { name } }) => {
        return <Typography>{name ? name : "Not available"}</Typography>;
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: ({ row: { email } }) => {
        return <Typography>{email ? email : "Not available"}</Typography>;
      },
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      flex: 1,
      renderCell: ({ row: { phone_number } }) => {
        return (
          <Typography>
            {phone_number ? phone_number : "Not available"}
          </Typography>
        );
      },
    },
    {
      field: "number_of_guest",
      headerName: "Number of Guest",
      flex: 1,
      renderCell: ({ row: { number_of_guest } }) => {
        return (
          <Typography>
            {number_of_guest ? number_of_guest : "Not available"}
          </Typography>
        );
      },
    },
    {
      field: "check_in",
      headerName: "Check in ",
      flex: 1,
      renderCell: ({ row: { check_in } }) => {
        return (
          <Typography>
            {check_in
              ? moment(check_in).format("Do MMMM YYYY")
              : "Not available"}
          </Typography>
        );
      },
    },
    {
      field: "check_out",
      headerName: "Check out",
      flex: 1,
      renderCell: ({ row: { check_out } }) => {
        return (
          <Typography>
            {check_out
              ? moment(check_out).format("Do MMMM YYYY")
              : "Not available"}
          </Typography>
        );
      },
    },
  ];
  return (
    <Box mt="20px">
      {bookings?.loading ? (
        <Box className="loader____container">
          <Box className="loader" />
        </Box>
      ) : (
        <>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{
              flexDirection: { xs: "column", sm: "row" },
              alignItems: { xs: "flex-start", sm: "flex-end" },
            }}
          >
            <Header title="Booking List" subtitle="Managing the bookings" />
            <Box>
              <Tooltip title="Download bookings Details">
                <Button
                  sx={{ height: "40px" }}
                  variant="outlined"
                  onClick={() => handleExportDetails()}
                >
                  <DownloadIcon />
                </Button>
              </Tooltip>
            </Box>
          </Box>
          <Box
            // width={"100%"}
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                minWidth: "250px !important",
                width: "250px !important",
                borderBottom: "none !important ",
                "&:focus": {
                  outline: "none !important",
                },
              },
              "& .MuiDataGrid-columnHeader": {
                minWidth: "250px !important",
                width: "250px !important",
                fontSize: "13px",
                color: "rgb(131 131 169/0.9) !important",
                fontWeight: "700 !important",
                textTransform: "uppercase !important",
              },
              "& .MuiDataGrid-virtualScroller": {
                overflowX: "auto !important",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                color: "white",
              },
              "& .MuiDataGrid-row": {
                maxHeight: "75px !important",
                height: "70px !important",
                padding: "10px 0px !important",
                borderBottom: "0.5px solid #f0f0f0 !important",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-withBorderColor": {
                borderColor: "#f0f0f0 !important",
              },
            }}
          >
            <DataGrid
              disableRowSelectionOnClick
              getRowId={(row) => row._id}
              rows={bookings?.data}
              columns={columns}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default BookingList;
