import React, { useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./index.css";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const propertyFields = [
  { name: "name", label: "Name", type: "text" },
  { name: "price", label: "Price", type: "number" },
  {
    name: "price_time_period",
    label: "Price Time Period (months/years/day/night)",
    type: "text",
  },
];

const PropertyAddressList = [
  { name: "address", label: "Address", type: "text" },
  { name: "city", label: "City", type: "text" },
  { name: "state", label: "State", type: "text" },
  { name: "zip", label: "Zip", type: "text" },
  { name: "country", label: "Country", type: "text" },
];

const PropertyDescription = [
  // { name: "description", label: "Description", type: "textarea" },
  { name: "map_url", label: "Add Map Url", text: "text" },
  { name: "short_description", label: "Short Description", type: "textarea" },
];

const PropertyApartmentDetails = [
  { name: "rooms", label: "Rooms", type: "number" },
  { name: "baths", label: "Baths", type: "number" },
  { name: "beds", label: "Beds", type: "number" },
  { name: "number_of_guest", label: "Number Of Guest", type: "number" },
];

const SeoDetails = [
  { name: "seo_title", label: "Title", type: "text" },
  { name: "seo_meta_description", label: "Meta Description", type: "text" },
  { name: "seo_keyword", label: "Keyword", type: "text" },
];
const openNewTab = () => {
  window.open(
    "https://www.embedmymap.com/?gclid=CjwKCAjw7c2pBhAZEiwA88pOFyOJP-qeqBYLDqgcsq6-Y7MAdnzFb6jYb9MgxS-6sDb2ByBmd6GzThoC440QAvD_BwE",
    "_blank"
  );
};

const AddPropertyForm = ({ formik, loading, next, text, activeStep }) => {
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleQuillChange = (content, _, __, editor) => {
    formik.setFieldValue("description", editor.getHTML()); // Set Formik value for description
  };
  return (
    <Box
      component={"form"}
      noValidate
      onSubmit={formik?.handleSubmit}
      sx={{ mt: 4 }}
    >
      <Box
        display="grid"
        gap="20px 45px"
        gridTemplateColumns="repeat(2, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isNonMobile ? undefined : "span 2",
          },
        }}
      >
   

        {propertyFields?.map((field, index) => (
          <TextField
            key={index}
            size="small"
            type={field?.type}
            placeholder={field?.label}
            onChange={formik.handleChange}
            value={formik.values[field?.name]}
            name={field?.name}
            error={!formik.values[field?.name] && !!formik.errors[field?.name]}
            helperText={
              !formik.values[field?.name] && formik.errors[field?.name]
            }
          />
        ))}
      </Box>
      <Typography className="form___title__text">
        Property Description
      </Typography>

      <div style={{ marginBottom: "20px", marginTop: "20px" }}>
        <ReactQuill
          placeholder="Type Description"
          theme="snow"
          value={formik.values["description"]}
          onChange={handleQuillChange}
        />
      </div>
      {/* name={field?.name}
              value={formik.values[field?.name]}
              onChange={formik.handleChange} */}

      <Box
        display="grid"
        gap="20px 45px"
        gridTemplateColumns="repeat(2, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isNonMobile ? undefined : "span 2",
          },
        }}
      >
        {PropertyDescription?.map((field, index) => (
          <Box key={index}>
            <textarea
              style={{
                opacity: 0.5,
                width: "100%",
                padding: "10px",
                border: `${
                  !formik.values[field?.name] && !!formik.errors[field?.name]
                    ? "1px solid red"
                    : "1px solid #ccc"
                }`,
                borderRadius: "4px",
              }}
              className="textareas_fields"
              name={field?.name}
              placeholder={field?.label}
              value={formik.values[field?.name]}
              onChange={formik.handleChange}
              rows={4}
            ></textarea>
            {!formik.values[field?.name] && !!formik.errors[field?.name] && (
              <Typography sx={{ color: "red" }}>
                {formik.errors[field?.name]}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
      <Typography
        sx={{ color: "blue", cursor: "pointer" }}
        onClick={openNewTab}
      >
        Generate map url
      </Typography>
      <Typography className="form___title__text">Property Address</Typography>
      <Box
        display="grid"
        gap="20px 45px"
        gridTemplateColumns="repeat(2, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isNonMobile ? undefined : "span 2",
          },
        }}
      >
        {PropertyAddressList?.map((field, index) => (
          <TextField
            key={index}
            size="small"
            type={field?.type}
            placeholder={field?.label}
            onChange={formik.handleChange}
            value={formik.values[field?.name]}
            name={field?.name}
            error={!formik.values[field?.name] && !!formik.errors[field?.name]}
            helperText={
              !formik.values[field?.name] && formik.errors[field?.name]
            }
          />
        ))}
      </Box>
      <Typography className="form___title__text">
        Property Apartment information
      </Typography>
      <Box
        display="grid"
        gap="20px 45px"
        gridTemplateColumns="repeat(2, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isNonMobile ? undefined : "span 2",
          },
        }}
      >
        {PropertyApartmentDetails?.map((field, index) => (
          <TextField
            key={index}
            size="small"
            type={field?.type}
            placeholder={field?.label}
            onChange={formik.handleChange}
            value={formik.values[field?.name]}
            name={field?.name}
            error={!formik.values[field?.name] && !!formik.errors[field?.name]}
            helperText={
              !formik.values[field?.name] && formik.errors[field?.name]
            }
          />
        ))}
      </Box>
      <Typography className="form___title__text">Property Host User</Typography>
      <Box
        display="grid"
        gap="20px 45px"
        gridTemplateColumns="repeat(2, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isNonMobile ? undefined : "span 2",
          },
        }}
      >
        <TextField
          size="small"
          type={"text"}
          placeholder={"Host user"}
          onChange={formik.handleChange}
          value={formik.values.host_name}
          name={"host_name"}
          error={!formik.values.host_name && !!formik.errors.host_name}
          helperText={!formik.values.host_name && formik.errors.host_name}
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
        <FormControlLabel
          control={<Checkbox checked={formik?.values?.cancel_access} />}
          label="Need to add cancellation policy ?"
          name="cancel_access"
          onChange={formik.handleChange}
        />
        {formik?.values?.cancel_access && (
          <TextField
            size="small"
            type={"text"}
            placeholder={"Add cancellation policy"}
            onChange={formik.handleChange}
            value={formik.values.cancel_policy}
            name={"cancel_policy"}
            error={
              !formik.values.cancel_policy && !!formik.errors.cancel_policy
            }
            helperText={
              !formik.values.cancel_policy && formik.errors.cancel_policy
            }
          />
        )}
      </Box>

      <Typography className="form___title__text">SEO</Typography>
      <Box
        display="grid"
        gap="20px 45px"
        gridTemplateColumns="repeat(2, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isNonMobile ? undefined : "span 2",
          },
        }}
      >
        {SeoDetails?.map((field, index) => (
          <TextField
            key={index}
            size="small"
            type={field?.type}
            placeholder={field?.label}
            onChange={formik.handleChange}
            value={formik.values[field?.name]}
            name={field?.name}
          />
        ))}
      </Box>
      <Box display="flex" justifyContent="end" mt="20px">
        <Button
          className="prev"
          variant="outlined"
          sx={{ mr: 2 }}
          onClick={() => navigate("/properties")}
        >
          <ArrowBackIcon />
        </Button>

        {(activeStep?.id || propertyId) && (
          <Button
            variant="outlined"
            className="next"
            sx={{ mr: 3 }}
            onClick={() => next()}
          >
            <ArrowForwardIcon />
          </Button>
        )}

        {loading?.propertyForm ? (
          <Button color="secondary" variant="outlined">
            <CircularProgress size={20} />
          </Button>
        ) : (
          <Button type="submit" color="secondary" variant="outlined">
            {text}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AddPropertyForm;
