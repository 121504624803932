export const mockAmenities = [
    "Indoors Air Condition",
    " Room Heaters",
    "Wifi",
    "Parking",
    "Geyser",
    "Swimming Pool",
    "Kitchen Essentials",
    "Bathroom Essentials",
    "Bedroom Essentials",
    "Indoor Games",
    "Outdoor Games",
    "Safety and Security",
    "Barbeque",
    "In-house Food",
    "Bonfire"
]

export const houseRules = [
    'Pet allowed',
    "Commercial photography",
    "Smoking is allowed"
]

export const safetyDevice = [
    "Security camera",
    "Carbon monoxide alarm",
    "Smoke alarm"
]

export const imageTypes = [
    { type: "bedroom", images: [], label: 'Bedroom' },
    { type: "bathroom", images: [], label: 'Bathroom' },
    { type: "kitchen", images: [], label: 'Kitchen' },
    { type: "lawn", images: [], label: 'Lawn' },
    { type: "outdoor_spaces", images: [], label: 'Outdoor Spaces' },
    { type: "swimming", images: [], label: 'Swimming pool' },
    { type: "additional__photos", images: [], label: 'Additional photos' },
]