import React, { useState, useEffect } from "react";
import { Box, StepLabel } from "@mui/material";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { propertyInitialValues, propertySchema } from "../../utils/validation";
import {
  imageTypes,
  mockAmenities,
  houseRules,
  safetyDevice,
} from "../../utils/mockData";
import API from "../../config/api";
import Step from "@mui/material/Step";
import Header from "../../components/Header";
import Stepper from "@mui/material/Stepper";
import AddThumbnails from "../../components/AddThumbnail";
import AddPropertyForm from "../../components/AddPropertyForm";
import AddRulesAndSafety from "../../components/AddRules&Safety";
import AddCoverPhoto from "../../components/AddCoverPhoto";
import "./index.css";

const steps = [
  "Add Property Details",
  "Add Rules & Safety",
  "Add Images ",
  "Add Cover",
];

const PropertyForm = ({ user }) => {
  const api = new API();
  const navigate = useNavigate();
  let location = useLocation();
  const { propertyId } = useParams();
  const [loading, setLoading] = useState({
    propertyForm: false,
    propertyRule: false,
    thumbnailLoading: false,
  });
  const [nestedArray, setNestedArray] = useState({
    amenities: mockAmenities,
    house_roles: houseRules,
    safety_property: safetyDevice,
  });
  const [thumbnails, setThumbnails] = useState(imageTypes);
  const [coverContainer, setCoverContainer] = useState({
    coverImagesList: [],
    coverImage: "",
    coverModel: false,
  });
  const [activeStep, setActiveStep] = useState({
    step: location?.state === "update_cover" ? 3 : 0,
    id: "",
  });

  useEffect(() => {
    if (propertyId) {
      fetchProperty();
    }
  }, [propertyId]);

  // handle property api calling
  const formik = useFormik({
    initialValues: propertyInitialValues,
    validationSchema: propertySchema,
    onSubmit: async (values) => {
      values.location = {
        address: values.address,
        city: values.city,
        state: values.state,
        zip: values.zip,
        country: values.country,
      };
      values.created_by = user?._id;
      values.cancellation = {
        have_access: values.cancel_access,
        cancel_policy: values.cancel_access ? values.cancel_policy : "",
      };
      delete values.address;
      delete values.city;
      delete values.state;
      delete values.zip;
      delete values.country;

      try {
        setLoading({ ...loading, propertyForm: true });
        const requestId = propertyId || activeStep?.id;
        let response = requestId
          ? await api.put(`api/property/${user._id}/${requestId}`, values)
          : await api.post(`api/property/${user._id}`, values);
        if (response?.success) {
          setLoading({ ...loading, propertyForm: false });
          formik.setValues({
            ...response?.data,
            address: response?.data?.location?.address,
            city: response?.data?.location?.city,
            state: response?.data?.location?.state,
            zip: response?.data?.location?.zip,
            country: response?.data?.location?.country,
            cancel_access: response?.data?.cancellation.have_access,
            cancel_policy: response?.data?.cancellation.cancel_policy,
          });
          setActiveStep({
            step: activeStep?.step + 1,
            id: response?.data?._id,
          });
          toast.success(
            `Property ${propertyId ? "updated" : "added"} sucessfully.`
          );
        }
      } catch (err) {
        setLoading({ ...loading, propertyForm: false });
      }
    },
  });

  const next = () => {
    setActiveStep({
      ...activeStep,
      step: activeStep?.step + 1,
    });
  };

  const prev = () => {
    setActiveStep({
      ...activeStep,
      step: activeStep?.step - 1,
    });
  };

  // get all properties
  const fetchProperty = async () => {
    const response = await api.get(`api/property/${user._id}/${propertyId}`);
    if (response?.success) {
      formik.setValues({
        ...response?.data,
        address: response?.data?.location?.address,
        city: response?.data?.location?.city,
        state: response?.data?.location?.state,
        zip: response?.data?.location?.zip,
        country: response?.data?.location?.country,
        cancel_access: response?.data?.cancellation.have_access,
        cancel_policy: response?.data?.cancellation.cancel_policy,
      });
      const thumbnailList =
        response?.data?.thumbnails?.length > 0
          ? response?.data?.thumbnails
          : imageTypes;
      setThumbnails(thumbnailList);
      setNestedArray({
        amenities: response?.data?.amenities,
        house_roles: response?.data?.house_roles || [],
        safety_property: response?.data?.safety_property || [],
      });
      setCoverContainer({
        ...coverContainer,
        coverImagesList: response?.data?.thumbnails,
        coverImage: response?.data?.cover_image || "",
      });
    }
  };

  // handle rules api calling
  const submitPropertyRules = async () => {
    const requestBody = {
      amenities: nestedArray.amenities,
      house_roles: nestedArray.house_roles,
      safety_property: nestedArray.safety_property
    };
    const requestId = propertyId ? propertyId : activeStep?.id;
    const response = await api.put(`api/properties/${requestId}`, requestBody);
    if (response?.success) {
      toast.success("Sucessfully added data.");
      setNestedArray({
        amenities: response?.data?.amenities,
        house_roles: response?.data?.house_roles,
        safety_property: response?.data?.safety_property,
      });
      const thumbnailList =
        response?.data?.thumbnails?.length > 0
          ? response?.data?.thumbnails
          : [...imageTypes];
      setThumbnails(thumbnailList);
      setLoading({ ...loading, propertyRule: false });
      next(response);
      setCoverContainer({
        ...coverContainer,
        coverImagesList: response?.data?.thumbnails,
        coverImage: response?.data?.cover_image || "",
      });
    }
  };

  // handle image api calling
  const submitImageUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (let data of thumbnails) {
      formData.append(`type`, data?.type);
      data?.images?.forEach((key) => {
        formData.append(
          `${formik?.values?.name?.replaceAll(" ", "_")}_image_${
            data?.type
          }_${new Date().getTime()}`,
          key
        );
      });
    }
    try {
      const requestId = propertyId ? propertyId : activeStep?.id;
      setLoading({ ...loading, thumbnailLoading: true });
      const response = await api.post(
        `api/property/${user._id}/${requestId}`,
        formData
      );
      if (response?.success) {
        setTimeout(() => {
          setLoading({ ...loading, thumbnailLoading: false });
          setThumbnails(response?.data?.thumbnails);
          setCoverContainer({
            ...coverContainer,
            coverImagesList: response?.data?.thumbnails,
            coverImage: response?.data?.cover_image || "",
          });
          toast.success("Property added sucessfully.");
          next(response);
        }, 2000);
      }
    } catch (err) {
      setLoading({ ...loading, thumbnailLoading: false });
    }
  };

  // handle image upload onchange functionality
  const handleImageUpload = (event, key, index) => {
    const files = event?.target?.files;
    const newImages = [];
    for (let i = 0; i < files?.length; i++) {
      newImages.push(files[i]);
    }
    if (thumbnails[index]["images"]?.length > 0) {
      thumbnails[index]["images"]?.forEach((image) => newImages.push(image));
    }
    thumbnails[index]["images"] =
      thumbnails[index]["type"] === key && newImages;
    setThumbnails([...thumbnails]);
  };

  // handle amenities on changes
  const handleAmenities = (e, action, state) => {
    let newList = [...nestedArray[state]];
    if (action === "add" && !newList?.find((i) => i === e)) {
      newList?.push(e);
    } else if (action === "remove") {
      newList = nestedArray[state]?.filter((amenity) => amenity !== e);
    }
    setNestedArray({
      ...nestedArray,
      [state]: newList,
    });
  };

  // upload Cover Image
  const handleSubmitCoverImage = async () => {
    let requestPayload = {};

    if (typeof coverContainer?.coverImage === "string") {
      requestPayload = { image: coverContainer?.coverImage, type: "string" };
    } else {
      requestPayload = new FormData();
      requestPayload.append(`image_cover_image`, coverContainer?.coverImage);
    }
    try {
      const response = await api.post(
        `api/coverimage/${activeStep?.id || propertyId}`,
        requestPayload
      );
      if (response?.success) {
        navigate("/properties");
        setCoverContainer({
          coverImagesList: [],
          coverModel: false,
          coverImage: response?.data?.cover_image || "",
        });
      }
    } catch (err) {
      console.log("----err----", err);
    }
  };

  const removeImageFromContainer = async (imageIndex, typeIndex, image) => {
    thumbnails[typeIndex]["images"].splice(imageIndex, 1);
    if (typeof image == "string") {
      await api.post(`api/removeImages`, {
        imageList: [image],
        thumbnails,
        propertyId,
      });
    }
    setThumbnails([...thumbnails]);
  };

  const text = propertyId ? "Update property" : "Add property";

  console.log("nestedArray-------------------------------------", nestedArray);
  return (
    <Box m="20px">
      <Header title={text} subtitle="Create a new property" />
      <Box>
        <Stepper activeStep={activeStep?.step} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          {activeStep?.step === 0 ? (
            <AddPropertyForm
              formik={formik}
              next={next}
              text={text}
              loading={loading}
              activeStep={activeStep}
            />
          ) : activeStep?.step === 1 ? (
            <AddRulesAndSafety
              next={next}
              prev={prev}
              loading={loading}
              activeStep={activeStep}
              nestedArray={nestedArray}
              setNestedArray={setNestedArray}
              handleAmenities={handleAmenities}
              submitPropertyRules={submitPropertyRules}
            />
          ) : activeStep?.step === 2 ? (
            <AddThumbnails
              next={next}
              prev={prev}
              loading={loading}
              activeStep={activeStep}
              thumbnails={thumbnails}
              submitImageUpload={submitImageUpload}
              handleImageUpload={handleImageUpload}
              removeImageFromContainer={removeImageFromContainer}
            />
          ) : activeStep?.step === 3 ? (
            <AddCoverPhoto
              next={next}
              prev={prev}
              formik={formik}
              coverContainer={coverContainer}
              setCoverContainer={setCoverContainer}
              handleSubmitCoverImage={handleSubmitCoverImage}
            />
          ) : null}
        </React.Fragment>
      </Box>
    </Box>
  );
};

export default PropertyForm;
