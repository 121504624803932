import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box } from '@mui/system';
import { DialogTitle, Radio } from '@mui/material';
import './index.css'

const ConfirmImageUploader = ({
    handleClose,
    formik,
    coverContainer,
    setCoverContainer,
    handleSubmitCoverImage,
    thumbnails,
    propertyId
}) => {
    // const imagesList = (coverContainer?.coverImagesList && coverContainer?.coverImagesList[0]?.images?.length) || 0
    const handleUpdate = (value) => {
        setCoverContainer({
            ...coverContainer,
            coverImage: value || "",
        });
    }
    return (
        <div>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={coverContainer?.coverModel}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="cover_photo_modal"
                classes={{ paper: "custom-dialog" }}
            >
                <DialogTitle style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "2% 7%"
                }}>Select an cover photo
                </DialogTitle>

                <DialogContent sx={{ display: "flex", flexWrap: 'wrap', justifyContent: "center" }}>
                    {coverContainer?.coverImagesList?.map((loop1) => {
                        return (loop1?.images?.map((loopImage) => {
                            return <Box key={loopImage} className='image___container' onClick={(e) => handleUpdate(loopImage)}>
                                <Box className='image___container__radio'>
                                    <Radio checked={coverContainer?.coverImage === loopImage}
                                        value={loopImage}
                                        size='small'
                                        className='image___container___radio__button'
                                    />
                                </Box>
                                <Box width='18vw' height='200px' style={{ borderRadius: "8px" }} component={'img'} src={loopImage} alt='product_img' />
                            </Box>
                        }))
                    })}
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button variant='outlined' onClick={handleClose}>Close</Button>
                    <Button variant='outlined' onClick={handleSubmitCoverImage}>Add cover image</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ConfirmImageUploader